import { useEffect, useState } from "react";
import axios from 'axios'
import PasswordChecklist from "react-password-checklist"
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom'
const Register = ()=>{
  const navigate = useNavigate();
  const [tab, settab] = useState('app')
  const [loadingtype, setloadingtype] = useState('Logging in')
    const [username, setusername] = useState('')
    const [gender, setgender] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [name, setname] = useState('')
    const [alart, setalart] = useState('')
    const [prog, setprog] = useState(0)
    const [vaild, setvaild] = useState(false)
    const cookies = new Cookies();
    const current = new Date();
    const nextYear = new Date();
useEffect(()=>{
  const userTokenc = cookies.get('userToken')
if(userTokenc){
  if(userTokenc.length > 10 && userTokenc.length < 401){
    settab('loading')
    setloadingtype('Logging in')
    axios.post('https://api.skyex.me/verfy',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
      // console.log(Response)
      if(Response){
        if(Response.data.status == 404){
          settab('app')
          setloadingtype('Registring')
        }else if(Response.data.status == 501){
          setloadingtype('Something went wrong please contact us')
          settab('loading')
        }
        else if(Response.data.status == 502){
          settab('loading')
          setloadingtype('Please Login again')
          setTimeout(() => {
            settab('app')
          }, 4000);
        }else if(Response.data.status == 200){
          setloadingtype('You have logged in succefuly')
          navigate('/portal', { replace: true })
          window.location.reload();
        }
      }
    })
      }else{
        settab('app')
    setloadingtype('Registring')
      }
}else{
  settab('app')
setloadingtype('Registring')
}

},[])
useEffect(()=>{
  if(tab == 'loading')
  window.scrollBy(0,-90000000);
},[tab])
    const register = ()=>{
        if(prog == 0){
            setprog(1)
            if(username.length < 3 || username.length > 20){
                setprog(0)
                setalart('username must be at least 3 characters and 20 max!')
            }else if(vaild == false){
              setprog(0)
                setalart('password must be at least 8 characters!')
            }else if(email.length < 4 || email.length > 50){
                setprog(0)
                setalart('You forgot yor email!')
            }else if(gender.length < 3 || gender.length > 10){
                setprog(0)
                setalart('Select gender!')
            }else if(name.length < 2 || name.length > 25){
              setprog(0)
              setalart('You forgot yor name!')
          }else{

              settab('loading')
              setloadingtype('Registering')
                axios.post('https://api.skyex.me/register',{username: username, gender: gender, password: password,email: email,name:name}).then((Response)=>{
                  // console.log(Response)
                    if(Response.data.status == 200){
                      // ok next step
                      setloadingtype('Logging in')
                      nextYear.setFullYear(current.getFullYear() + 80);
                      const userToken = Response.data.userToken
                      cookies.set('userToken', userToken, {
                          path: '/',
                          expires: nextYear,
                      });
                      localStorage.setItem('username',username)
                      setloadingtype('You have logged in succefuly')
                      navigate('/portal', { replace: true })
                      window.location.reload();
                    }else if(Response.data.status == 502){
                      // forget something
                      settab('app')
                      setloadingtype('Logging in')
                      setprog(0)
                      setalart('You forgot something!')
                    }else if(Response.data.status == 501){
                      // something wrong happened please try again later
                      settab('app')
                      setloadingtype('Logging in')
                      setprog(0)
                      setalart('something wrong happened please try again later')
                    }else if(Response.data.status == 201){
                      settab('app')
                      setloadingtype('Logging in')
                      setprog(0)
                      setalart('this username already exist')
                      // this acc already exist
                    }else if(Response.data.status == 202){
                      settab('app')
                      setloadingtype('Logging in')
                      setprog(0)
                      setalart('this email already exist')
                      // this acc already exist
                    }
                }).catch((Error)=>{
                  setloadingtype('Unable to connect to the backend please try againg later')
                    console.error('d')
                    setprog(0)
                })
            }








           

        }else{

        }

    }   
    if(tab == 'app'){
     return (
        <>
            <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white">
     

        <div className="hero -mb-12  w-screen bg-[#11131d]">
    {alart.length > 0 && (
        <div className="alert w-64 hero m-4">
  <div className="">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2196f3" className="w-7 h-7 mx-2 ">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
    </svg> 
    <label>{alart}</label>
  </div>
</div>
    )}

</div>

<div className="hero bg-[#11131d] ">
    
<div className="p-10 card bg-base-300 sm:max-w-96 md:w-96 mt-12 lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl ">
<div className="form-control mb-4 text-2xl -mt-2 text-center">
Register
  </div>


  <div className="form-control mb-2">
    <label className="label">
      <span className="label-text">Username</span>
    </label> 
    <input value={username} onChange={(e)=>{
        setusername(e.target.value.replace(/\s/g, ''))
    }} type="text" placeholder="username" className="input bg-base-200" />
  </div>
  <div className="form-control mb-2">
    <label className="label">
      <span className="label-text">Name</span>
    </label> 
    <input value={name} onChange={(e)=>{
        setname(e.target.value)
    }}type="text" placeholder="Name" className="input bg-base-200" />
  </div>
  <div className="form-control mb-2">
    <label className="label">
      <span className="label-text">Gender</span>
    </label> 
    <select onChange={(e)=>{
        setgender(e.target.value.replace(/\s/g, ''))
    }} className="select select-bordered w-full bg-base-200 border-base-200 max-w-xs">
  <option disabled="disabled" selected="selected">Gender</option> 
  <option value="male">Male</option> 
  <option value="female">Female</option>
  <option value="other">other</option> 
</select>
  </div>

  <div className="form-control mb-2">
    <label className="label">
      <span className="label-text">Email</span>
    </label> 
    <input type="text" value={email} onChange={(e)=>{setemail(e.target.value.replace(/\s/g, ''))}} placeholder="hi@domain.com" className="input bg-base-200" />
  </div>
  <div className="form-control  mb-2">
    <label className="label">
      <span className="label-text">Password</span>
    </label> 
    <input type="text" value={password} onChange={(e)=>{setpassword(e.target.value.replace(/\s/g, ''))}} placeholder="password" className="input bg-base-200" />

  {password.length > 0 &&(
  <div className="sm:w-60 md:w-96 mt-6 lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl">
  <PasswordChecklist
				rules={["lowercase","minLength","specialChar","number","capital"]}
				minLength={8}
				value={password}
				onChange={(isValid) => {setvaild(isValid)}}
			/>
  </div>
  )}


</div>

  <div className="form-control mt-2 mb-2">
    <button className="btn btn-active" role="button" onClick={register} aria-pressed="true">Register</button> 
  </div>
  
</div>

</div>


        </div>
<div className="h-32 w-screen bg-[#11131d]">

</div>
        <footer className="p-10  footer bg-[#11131d] mt-[vh] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
        </>
    )}else if(tab == 'loading'){
      return (
        <>
                    <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full bg-[#11131d] text-white">
     


<div className="hero">
    
<div className="p-10 card bg-base-300 sm:w-64 md:w-96 mt-12 lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl ">
<div className="form-control mb-4 text-2xl hero -mt-2 text-center">
{loadingtype}


  </div>
  <div className="hero">
  <button className="btn btn-lg bg-base-300 border-base-300 btn-circle loading"></button> 
  </div>
</div>
</div>

        </div>
<div className='h-96 w-screen bg-[#11131d]'>

</div>
        <footer className="p-10  footer bg-[#11131d] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
        </>
    )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Register