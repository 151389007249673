import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Darkfoot from "../darkfootbar";
const Cookiepolicy = ()=>{
  const navigate = useNavigate();
    return (
        <>
                                        <Helmet>
          <title>Skyex | Cookies</title>
          <meta
      name="description"
    
      content="Skyex Cookies."
    />
        </Helmet>
             <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white">
     








<div className="text-black text-2xl hero bg-[#11131d]">
<div id='c1' className="text-white shadow-2xl bg-[#11131d] m-8 max-w-lg p-6 rounded-lg">
<p id='f2' className="mb-5 hero">
Cookie Policy

          </p> 
          <p id='f1' className='text-lg'>
              <h1>INTRODUCTION</h1>
          
by using this site you agree to:
<br />
<br />
[Skyex] (“we” or “us” or “our” or "I") may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience. <br /><br />

We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.<br /><br />

You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.<br /><br />
<br />
<h1>
USE OF COOKIES
</h1>


A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, keep you logged into the Site, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience.

TYPES OF COOKIES
<br />
The following types of cookies may be used when you visit the Site:
<br /><br />
Analytics Cookies
<br /><br />
Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved.
<br /><br />
Our Cookies
<br /><br />
Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won’t work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.
<br /><br />
Personalization Cookies
<br /><br />
Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site.
<br /><br />
Security Cookies
<br /><br />
Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.
<br /><br />
Site Management Cookies
<br /><br />
Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.
<br /><br />
Third-Party Cookies
<br /><br />
Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser.
<br /><br />
[Other]
<br /><br />
CONTROL OF COOKIES
<br /><br />
Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site.
<br /><br />
For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:

          </p>
</div>

</div>



        </div>
        <div className='w-screen h-[30vh] bg-[#11131d]'>

        </div>
<Darkfoot />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Cookiepolicy