import react from "react";
import FootBar from "./footbar";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import "animate.css/animate.min.css";
import { Helmet } from "react-helmet";
import Darkfoot from "./darkfootbar";
const Home = ()=>{
  const navigate = useNavigate();
    return (
        <>
        <Helmet>
          <title>Skyex</title>
        <meta
      name="description"
      content="SkyEX is a cloud web hosting software for everyone. Our web hosting services are built for the demanding requirements of website owners and professionals."
    />
        </Helmet>

        <div className="w-full scrollbar-hide text-white">










        <div className="hero  bg-[#11131d] -mt-[6.53rem] min-h-screen w-screen shadow-2xl" style={{ 
      backgroundImage: `url("/bg.jpeg")` 
    }}>
  <div className='-mt-12'></div> 
  <div className="text-center hero-content text-neutral-content ">
    <div className="max-w-md">
      <h1 className="mb-5 text-5xl leading-tight font-bold">
      Servers in the Sky
          </h1> 
      <p id='f1' className="mb-5">
      <Typewriter
 
 onInit={(typewriter)=> {

 typewriter
  .pasteString("We make businesses")
 .typeString(" fly faster, easier, better.")

 .start();
 }}
 />
      
          </p> 
          
      <button className="btn" onClick={() =>{
         window.scrollBy(0,-90000000);
        navigate('/start', { replace: false })}}>Get Started</button>
      
    </div>
    
  </div>

</div>
<div className="flex justify-center">
{/* <div className="animate-bounce bg-white dark:bg-gray-800 p-2 w-10 h-10 ring-1 ring-gray-900/5 dark:ring-gray-200/20 shadow-lg rounded-full -mt-32">
      <svg className="w-6 h-6 text-violet-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
      </svg>
    </div> */}
</div>
<div className="grid text-black bg-cover bg-[#11131d] place-items-center " style={{ 
 
    }}>
<AnimationOnScroll animateOnce='true' offset='8' animateIn="animate__bounceIn">

<div id='f1' className="w-auto mt-8 text-white text-4xl">
Features
</div>
</AnimationOnScroll>
<div className="grid mt-4 mb-16">
  {/* 1 */}
<div id='' className='p-4 mt-4 sm:w-auto bg-gradient-to-r from-gray-800 to-gray-900 md:w-96 lg:w-[40rem] rounded-lg text-white'>
  <div className='flex-none'>
<i className="fa-solid fa-shield text-xl pr-3"></i>
<span className='-pt-2'>Advanced DDoS protection</span>
  </div>
</div>
{/* 2 */}
<div id='afford' className='p-4 mt-4 rounded-lg text-white'>
  <div className='flex-none'>
  <i className=""></i>
<i className="fa-solid fa-tags  text-xl pr-3"></i>
<span className='-pt-2'>Affordable prices</span>
  </div>
</div>

{/* 3 */}
<div id='greencard' className='p-4 mt-4 rounded-lg text-white'>
  <div className='flex-none'>

<i className="fa-solid fa-solar-panel  text-xl pr-3"></i>
<span className='-pt-2'>100% Green Energy</span>
  </div>
</div>
{/* 4 */}
<div id='greencard' className='p-4 mt-4 rounded-lg text-white'>
  <div className='flex-none'>
<i className="fa-solid fa-calendar-week  text-xl pr-3"></i>
<span className='-pt-2'>Available 24/7 with 99.9% uptime</span>
  </div>
</div>
{/* 5 */}
<div id='greencard' className='p-4 mt-4 rounded-lg text-white'>
  <div className='flex-none'>
<i className="fa-solid fa-jet-fighter text-xl pr-3"></i>
<span className='-pt-2'>Easy-to-use control panels</span>
  </div>
</div>
{/* <div>

</div>
<div>

</div>
<div>

</div> */}
</div>
<div className='grid bg-[#1113j1d]  mb-16'>
<AnimationOnScroll animateOnce='true' offset='8' animateIn="animate__bounceIn">
<div id='f1' className="w-auto mt-8 text-white text-center text-4xl">
Frequently Asked Questions
</div>
</AnimationOnScroll>
<div className='text-white md:px-32 p-5'>

  {/* 1 */}
<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  Can I connect my existing domain?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>Yes, you will need to enter two records into your domain DNS in order to connect the site. Please reach out to us if you are having trouble connecting it.</p>
  </div>
</div>
{/*  */}
<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  Can You Help Me Move My Existing Sites Over?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>
We won’t just help; we’ll do all the work for you. Regardless of how many sites you currently have. Speak to our technical team once you’ve picked your desired plan and we’ll handle the rest.</p>
  </div>
</div>
{/* 2 */}
<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  What Payment Gateways do you offer?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>For now PayPal and stripe Booth support debit and credit cards.</p>
  </div>
</div>
{/* 3 */}
<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  What billing cycles do you offer?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>You can pay monthly or for 1, 2 or 3 years up front. If you decide to pre-pay, we give you some great discounts on our ongoing pricing,to pre-pay you would have to contact support.</p>
  </div>
</div>

<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  Do you offer discounts for charities?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>Yes!, contact Support for more information.</p>
  </div>
</div>
<div tabindex="0" className="collapse mt-6 collapse-arrow border text-sm border-base-300 focus:bg-base-300 bg-base-200 rounded-box">
  <div className="collapse-title text-lg font-medium">
  What is CloudFlare protection?
  </div>
  <div className="collapse-content bg-base-300"> 
    <p>Cloudflare DDoS protection and Proxy secures websites, applications, and entire networks while ensuring the performance of legitimate traffic is not compromised .</p>
  </div>
</div>


</div>

</div>

</div>


        </div>

<Darkfoot />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Home