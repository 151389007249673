import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Darkfoot from "../darkfootbar";
import { Helmet } from "react-helmet";
const Myaccount = ()=>{
    
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('SUPPORT PIN')
    const [tab, settab] = useState('loading')
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)
    const [support, setsupport] = useState('');
    const cookies = new Cookies();
    useEffect(()=>{
        const userTokenc = cookies.get('userToken')
      if(userTokenc){
        if(userTokenc.length > 10 && userTokenc.length < 401){
          settab('loading')
          axios.post('https://api.skyex.me/account',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
            // console.log(Response)
            if(Response){
              if(Response.data.status == 404){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              
              }else if(Response.data.status == 501){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              }
              else if(Response.data.status == 502){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              }else if(Response.data.status == 200){
                // setloadingtype('You have logged in succefuly')
                setusername(Response.data.username)
                setsupport(Response.data.callpin)
                setemail(Response.data.email)
                setname(Response.data.name)
                settab('app')
              }
            }
          })
            }else{
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
            }
      }else{
        navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
      }
      
      },[])
      if (tab == 'app'){

      
    return (
        <>
        <Helmet>
          <title>Skyex | My account</title>
          <meta
      name="description"
    
      content="Skyex | My account. Edit your account. Get your support pin. Change password"
    />
        </Helmet>
                                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d]">
  








<div className="text-white w-screen text-2xl hero">

<div className="p-10 grid sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">
<div id='f5'>
    My Account
</div>
<div className="hero">
    <img  src={avatat} alt='pfp' className="w-52"/>
</div>    
  <div className="form-control">
    <label className="label">
      <span className="label-text">Name</span>
    </label> 
    <input type="text" value={name} onChange={(e)=>{setname(e.target.value)}} placeholder="Name" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
    <label className="label">
      <span className="label-text " >Username</span>
    </label> 
    <input type="text" value={username} placeholder="username" disabled="disabled" className="input bg-base-200 ring ring-base-100 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
    <label className="label">
      <span className="label-text">Email</span>
    </label> 
    <input type="text" value={email} onChange={(e)=>{setemail(e.target.value)}} placeholder="Email" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
  {/* <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]">Change Password</button> 
  <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]">Change Token</button>  */}
{callpin == 'SUPPORT PIN' && (
  <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]" onClick={()=>{
    setcallpin(support)
}}>{callpin}</button> 
)}

{callpin == support && (
  <div className="mt-3 sm:w-auto md:w-[20rem] rounded p-2 border text-xs border-white">
{callpin}
  </div>
  )}

{/* <button type="submit" className="btn bg-base-300 mt-3 sm:w-auto md:w-[20rem]">Save</button> */}

  </div>
  
</div>

    <div className=" ">

    </div>

</div>



        </div>

        <div className="h-[30vh] w-screen bg-[#11131d]">

        </div>
<Darkfoot />
        </>
    )}else if(tab == 'loading'){
        return(
            <>
<div className=" bg-[#11131d] h-[110vh]">
<div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
             <div className="hero mt-[30vh]">
             <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
      
</div>
</div>
      </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Myaccount