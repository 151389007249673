import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment';
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import { Helmet } from "react-helmet";
import Darkfoot from "../darkfootbar";
const Products = ()=>{
    
  const navigate = useNavigate();
  const [products, setproducts] = useState([])
  const [fproducts, setfproducts] = useState([])
  const [avatat, setavatat] = useState("/logo.png")
    const [tab, settab] = useState('loading')
    const cookies = new Cookies();
    useEffect(()=>{
      const userTokenc = cookies.get('userToken')
    if(userTokenc){
      if(userTokenc.length > 10 && userTokenc.length < 401){
        axios.post('https://api.skyex.me/products',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
          // console.log(Response)
          if(Response){
            if(Response.data.status == 404){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else if(Response.data.status == 501){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }
            else if(Response.data.status == 502){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else{
              // console.log(Response.data)

              setproducts(Response.data)
              axios.post('https://api.skyex.me/fproducts',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
                // console.log(Response)
                if(Response){
                  if(Response.data.status == 404){
                    navigate('/login', { replace: false })
                    window.scrollBy(0,-90000000);
                  }else if(Response.data.status == 501){
                    navigate('/login', { replace: false })
                    window.scrollBy(0,-90000000);
                  }
                  else if(Response.data.status == 502){
                    navigate('/login', { replace: false })
                    window.scrollBy(0,-90000000);
                  }else{
                    console.log(Response.data)
                    
                    setfproducts(Response.data)
      settab('app')
                  }
                }
              })


            }
          }
        })
          }else{
            navigate('/login', { replace: false })
            window.scrollBy(0,-90000000);
          }
    }else{
      navigate('/login', { replace: false })
      window.scrollBy(0,-90000000);
    }
    
    },[])
      if (tab == 'app'){

      
    return (
        <>
                        <Helmet>
          <title>Skyex | Products</title>
          <meta
      name="description"
    
      content="Skyex Products control your products."
    />
        </Helmet>
                        <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>

        <div className="w-full bg-base-00 -mt-2 text-white bg-[#11131d] ">
  








<div className="text-white w-screen  hero">

<div className="p-4 grid  place-items-center ">
<div id='f5' className='text-2xl mb-4'>
    Products
</div>
<div className="grid">

<div className="overflow-x-auto ">
  <table className="table md:w-[40rem]">
    <thead>
      <tr>
        <th>ID</th> 
        <th>Name</th> 
        <th>price</th> 
        <th>creation Date</th>
        <th>View</th>
      </tr>
    </thead> 
    <tbody>
      {products.map((gf,i)=>{
        return (
          <tr className="">
          <th>{gf.id}</th> 
          <td>{gf.product_name}</td> 
          <td className='text-green-700'>{gf.product_price}USD</td> 
          <td><Moment format="DD/MM/YYYY">{gf.next_due_date}</Moment></td>
          <td onClick={()=>{
       navigate('/product/' + gf.id, { replace: false })
       window.scrollBy(0,-90000000);}} className='text-sky-600 cursor-pointer'>View</td>
        </tr>
        )
      })}

    </tbody>
  </table>
  
  {products.length == 0 && (
        <div className="text-center hero flex justify-center">
        <h2 id='f1' className="text-white mt-2 text-center hero">You have 0 products</h2>

        </div>)}
        
</div>
{fproducts.length > 0 && (
  <>
<div id='f5' className='text-2xl text-center mt-12 mb-4'>
      Free Services
  </div>
<div className="overflow-x-auto ">
  <table className="table md:w-[40rem]">
    <thead>
      <tr>
        <th>ID</th> 
        <th>Name</th> 
        <th>price</th> 
        <th>creation Date</th>
        <th>View</th>
      </tr>
    </thead> 
    <tbody>
    {fproducts.map((gf,i)=>{
          return (
            <tr className="">
            <th>{gf.id}</th> 
            <td>{gf.product_name}</td> 
            <td className='text-green-700'>{gf.product_price}USD</td> 
            <td><Moment format="DD/MM/YYYY">{gf.next_due_date}</Moment></td>
            <td onClick={()=>{
         navigate('/fproduct/' + gf.id, { replace: false })
         window.scrollBy(0,-90000000);}} className='text-sky-600 cursor-pointer'>View</td>
          </tr>
          )
        })}

    </tbody>
  </table>      
</div>
</>)}
{/* {fproducts.length > 0 && (
  <div className="p-4 mt-5 grid place-items-center md:w-[40rem]">
  <div id='f5' className='text-2xl mb-4'>
      Free Services
  </div>
  <div className="overflow-x-automd:w-[40rem] ">
    <table className="table md:w-[40rem]">
      <thead>
        <tr>
          <th>ID</th> 
          <th>Name</th> 
          <th>price</th> 
          <th>creation Date</th>
          <th>View</th>
        </tr>
      </thead> 
      <tbody>
        {fproducts.map((gf,i)=>{
          return (
            <tr className="">
            <th>{gf.id}</th> 
            <td>{gf.product_name}</td> 
            <td className='text-green-700'>{gf.product_price}USD</td> 
            <td><Moment format="DD/MM/YYYY">{gf.next_due_date}</Moment></td>
            <td onClick={()=>{
         navigate('/fproduct/' + gf.id, { replace: false })
         window.scrollBy(0,-90000000);}} className='text-sky-600 cursor-pointer'>View</td>
          </tr>
          )
        })}
  
      </tbody>
    </table>
  
  </div>
  </div>
)} */}
</div>
<button className='btn mt-5' onClick={()=>{
  navigate('/start', { replace: false })
}}>Buy a product</button>
</div>

    <div className=" ">
    
    </div>

</div>


<div className="h-[40vh] bg-[#11131d] ">

</div>
 
<Darkfoot />
</div>
        </>
    )}else if(tab == 'loading'){
        return(
            <>

<div className=" bg-[#11131d] h-[110vh]">
<div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
             <div className="hero mt-[30vh]">
             <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
             <p className='mt-[6rem] text-white'>Something Wrong? <span className='text-sky-600'><a href='https://twitter.com/skyex_me' target='_blank'>Tweet us</a></span> </p>
</div>
</div>
      </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Products