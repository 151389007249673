import { useNavigate } from 'react-router-dom'
const Term = ()=>{
  const navigate = useNavigate();
    return (
        <>
                    <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full bg-[#11131d]   text-white">
     








<div className="text-white text-5xl hero">
Terms of use
</div>
<div className="text-white sm:px-12 md:px-32 text-lg mt-16  ml-3">
        <div>

                <div className="text-3xl">#1 THESE TERMS</div>
                <div className="ml-3 mt-3">
                       
                        1- What these terms cover. These are the terms and conditions on which Skyex supply services to you. All terms are effective from the date of your craate an account. 
                       <div className='mt-3'></div>
                        2- We reserve the right to make changes to this terms at any time and for any reason. Any changes or modifications will be effective immediately.
                </div>
                <div className="text-3xl mt-6">#2 ACCOUNTS</div>
                <div>
                <div className='ml-2 mt-3'> We won't track, analyze, sell any of your accounts data.</div>
                </div>


                <div className="text-3xl mt-6">#3 PRODUCTS</div>
                <div className='ml-3 mt-3 text-2xl'> -WebHosting</div>
                <div className='ml-6 mt-3'>
               1. Free Domain Offer. <span className='text-gray-300'>Some of our hosting packages include a free domain. This applies to a limited number of domain extensions and may vary from time to time. We may allow or disallow a free registration or transfer at our discretion. The registration period is one year. Future renewals of these domains isn't availble now.</span>
                </div>
                <div className='ml-6 mt-3'>
               2. Purpose of Services. <span className='text-gray-300'>we are not responsible for what content you share and post but if we found any software that crashes the server we reserve the right to suspend the services for more investigation.</span>
                </div>
                <div className='ml-6 mt-3'>
               3. Reasons we may suspend or disable the service. <span className='text-gray-300'>
                       <div className='ml-9 mt-3'>1- deal with technical problems or make minor technical changes.</div>
                       <div className='ml-9 mt-3'>2- update the product to reflect changes in relevant laws and regulatory requirements.</div>
                       <div className='ml-9 mt-3'>3- make changes to the product as requested by you or notified by us to you.</div>
                       <div className='ml-9 mt-3'>4- when required by law.</div>
                       <div className='ml-9 mt-3'>5- to resolve any third-party claims or actions.</div>
               </span>
                </div>
        {/* 1- This site is still under development So all the orders will be cancelled and refunded <br /> <br />
2- we won't look, track, analyze any of your services data <br /> <br />
3- We reserve the right to make changes to this terms at any time and for any reason. Any changes or modifications will be effective immediately<br /> <br />
4-  <br /> <br /> */}
        </div>

</div>


        </div>
        <div className='h-32 w-screen bg-[#11131d] '></div>
        <footer className="p-10  footer bg-[#11131d] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Term