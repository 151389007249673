import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Notfound from "./com/landing/404";
import Aboutus from "./com/landing/aboutus";
import Invoices from "./com/accounts/invoices";
import Myaccount from "./com/accounts/myaccount";
import Pay from "./com/accounts/pay";
import Portal from "./com/accounts/portal";
import Product from "./com/accounts/product";
import Products from "./com/accounts/products";
import Cookiepolicy from "./com/landing/cookiepolicy";
import GetStarted from "./com/landing/getstarted";
import Home from "./com/home";
import Jobs from "./com/landing/jobs";
import Login from "./com/in/login";
import NavBar from "./com/navbar";
import OrderWeb from "./com/orders/orderweb";
import Register from "./com/in/register";
import Term from "./com/landing/termuse";
import Webhost from "./com/products/webhost";
import ReactGA from 'react-ga';
import LandingPage from "./com/landingpages";
import Blog from "./com/products/blog";
import OrderBlog from "./com/orders/orderblog";
import Vps from "./com/products/vps";
import OrderVps from "./com/orders/ordervps";
import Logout from "./com/accounts/logout";
import FProduct from "./com/accounts/fproduct";
import Minecraft from "./com/products/minecraft";
import Contact from "./com/landing/contactus";
import OrderMc from "./com/orders/ordermc";
import Landingpages from "./com/products/Landingpages";
import Giveaway from "./com/landing/giveaway";
import Verfygive from "./com/landing/verfygive";
import Ra7 from "./com/partner/p";
// ReactGA.initialize('G-8SD1MX0ZMC');
// ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
  <div className="w-full text-white">
  <div className="bg-base-200 w-screen">
          <NavBar />
          </div>
          </div>
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route path="/start" exact element={<GetStarted />}></Route>
      {/* <Route path="/start/store" exact element={<Store />}></Route> */}
      {/* <Route path="/start/landing" exact element={<LandingPage />}></Route> */}
      <Route path="/start/blog" exact element={<Blog />}></Route>
      <Route path="/webhost" element={<Webhost />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/aboutus" element={<Aboutus />}></Route>
      <Route path="/myaccount" element={<Myaccount />}></Route>
      <Route path="/Portal" exact element={<Portal />}></Route>
      <Route path="/Portal/pay/:id" exact element={<Pay />}></Route>
      <Route path="/jobs" element={<Jobs />}></Route>
      <Route path="/terms" element={<Term />}></Route>
      <Route path="/cookiepolicy" element={<Cookiepolicy />}></Route>
      {/* <Route path='/start/info/:product/:plane' exact element={<Storeedst />}/> */}
      <Route path='/product/:prid' exact element={<Product />}/>
      <Route path='/fproduct/:prid' exact element={<FProduct />}/>
      <Route path='/start/order/webhost/:plane' exact element={<OrderWeb />}/>
      <Route path='/start/vps' exact element={<Vps />}/>
      <Route path='/start/minecraft' exact element={<Minecraft />}/>
      <Route path='/start/landing' exact element={<Landingpages />}/>
      <Route path='/start/order/blog/:plane' exact element={<OrderBlog />}/>
      <Route path='/start/order/vps/:plane' exact element={<OrderVps />}/>
      <Route path='/start/order/mc/:plane' exact element={<OrderMc />}/>
      <Route path='/verfygive/:email/:id' exact element={<Verfygive />}/>
      <Route path='/r/:r' exact element={<Ra7 />}/>
      <Route path='/portal/products' exact element={<Products />}/>
      <Route path='/giveaway' exact element={<Giveaway />}/>
      <Route path='/portal/invoices' exact element={<Invoices />}/>
      <Route path='/contact' exact element={<Contact />}/>
      <Route path="/404" element={<Notfound />}></Route>
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
</>
  )
  
}

export default App;
