import react, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons,usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate,useParams } from 'react-router-dom'
import axios from "axios";
import Cookies from 'universal-cookie';
const Verfygive = ()=>{
  const navigate = useNavigate();
  let p = useParams()
  const email = p.email
  const id = p.id
  const [price, setprice] = useState('')
  const [tab, settab] = useState('loading')

  // const [{ isPending }] = usePayPalScriptReducer();
// useEffect(()=>{console.log(isPending)},[isPending])
useEffect(() => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        axios.post('https://api.skyex.me/verfyemail',{email:email,id:id}).then((Response) => {
            if(Response.data.status == 200){
                settab('approved')     
            }else{
                   settab('app')     
            }
        })
    }else{
        settab('app')
    }

}, [])






  if(tab == 'app'){
    return (
        <>
           <div className=" -mt-[6.53rem]  bg-[#11131d] h-[110vh]">
                <div className="bg-[#11131d] w-screen h-36">

                </div>
            <div className="hero ">
            <div className="p-10 h-[22rem] grid py-32 sm:w-auto md:w-[35rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">


            <div class="-mt-20">

	
	<div class="ui-error">
		<svg  viewBox="0 0 87 87" version="1.1" >
			<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Group-2" transform="translate(2.000000, 2.000000)">
						<circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
						<circle  class="ui-error-circle" stroke="#F74444" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
							<path class="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#F74444" stroke-width="3" stroke-linecap="square"></path>
							<path class="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#F74444" stroke-width="3" stroke-linecap="square"></path>
					</g>
			</g>
	</svg>
	</div>
</div>


<div className="text-xl text-white">
Invaild URL
</div>
<div className="text-gray-400">You might have already confirmed it</div>
</div>

            </div>
            </div>


      </>
    )}else if(tab == 'loading'){
        return(
            <>


            <div className=" -mt-[6.53rem]  scrollbar-hide bg-[#11131d] h-[110vh]">
            <div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">
            
            </div>
                         <div className="hero mt-[30vh]">
                         <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
            </div>
            </div>
              </>
          )
    }
    else if(tab == 'approved'){
        return(
            <>
            <div className=" -mt-[6.53rem]  bg-[#11131d] h-[110vh]">
                <div className="bg-[#11131d] w-screen h-36">

                </div>
            <div className="hero ">
            <div className="p-10 grid py-32 sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">


<div className="wrapper"> <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
       <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
       <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
   </svg>
</div>
<div className="text-xl text-white">
    Thank You
</div>
<div className="text-gray-400">You Have Confirmed Your Email Successfully</div>
</div>

            </div>
            </div>


      </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Verfygive