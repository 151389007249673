import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate,useParams } from 'react-router-dom'
import Moment from 'react-moment';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { Helmet } from "react-helmet";
const FProduct = ()=>{
    let p = useParams()
    const prid = p.prid
  const navigate = useNavigate();
  const [product, setproduct] = useState([])
  
  const [avatat, setavatat] = useState("/logo.png")
    const [tab, settab] = useState('loading')
    const cookies = new Cookies();
    useEffect(()=>{
      const userTokenc = cookies.get('userToken')
    if(userTokenc){
      if(userTokenc.length > 10 && userTokenc.length < 401){
        axios.post('https://api.skyex.me/fproduct',{prid:prid,userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
          // console.log(Response)
          if(Response){
            if(Response.data.status == 404){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else if(Response.data.status == 501){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }
            else if(Response.data.status == 502){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else{
              if(Response.data.length == 0){
                navigate('/portal', { replace: false })
                window.scrollBy(0,-90000000);
              }else{
                setproduct(Response.data)

              }
settab('app')
            }
          }
        })
          }else{
            navigate('/login', { replace: false })
            window.scrollBy(0,-90000000);
          }
    }else{
      navigate('/login', { replace: false })
      window.scrollBy(0,-90000000);
    }
    
    },[])
      if (tab == 'app'){

      
    return (
        <>
        
                <Helmet>
          <title>Skyex | Products</title>
          <meta
      name="description"
    
      content="Skyex Products control your products."
    />
        </Helmet>
                        <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>

        <div className="w-full bg-[#11131d] -mt-2 text-white">
  








<div className="text-white w-screen  hero">

<div className="p-4 grid  place-items-center ">
    <div className=" rounded-xl">
    <div id='ddos' className="p-8 sm:w-[20rem] md:w-[30rem] lg:w-[40rem] h-52 hero  text-white text-center rounded-xl text-3xl">
<div className="uppercase">
{product[0].product_name}

</div>

    </div>
    <div className="p-6">
    <div className='text-lg sm:text-center text-white lg:text-left' id='f6'>
   <span className="mr-2">Status:
       </span> 
       <div className="badge badge-accent rounded-lg">{product[0].sta}</div>
    {/* {product.map((gf,i)=>{
          const dateToFormat = gf.due_date;

        if(gf.order_id){
            if(gf.amount){
              if(parseFloat(gf.amount) == parseFloat(Object.values(gf)[0]) && gf.currncy == 'USD'){
                return(
  
           <div className="badge badge-accent rounded-lg">{product[0].pstatus}</div>

                )
              }else{
                return(
<div className="badge bg-red-700 border-red-700 badge-accent">WRONG PAYMENT</div>
                )
              }
        
            }else{
              return(
<div className="badge bg-orange-500 border-orange-500 badge-accent">Verifying</div>

              )
            }
        
          }else{
            return(
<div className="badge bg-red-700 border-red-700 badge-accent">UNPAID</div>

            )
          }
    })}
     */}
    
    
    
    
    

</div>


<div  className="lg:float-right sm:text-center text-white lg:text-left mt-3 mb-5 lg:-mt-[2.1rem]">
<strong id='f6' className='text-2xl'>Payment</strong>
<br />
<div className="mt-3">
Your next bill is for {product[0].product_price} on {product[0].Expire}

</div>
</div>
<div className="mt-3 sm:grid md:place-items-start sm:place-items-center mb-3">
{JSON.parse(product[0].adata).map((gf,i)=>{
  return (
<div>
    <button className="btn sm:text-center lg:text-left btn-sm mt-2"><a href={gf.link} target="_blank">{gf.name}</a></button>
</div>
  )
})}

</div>

    </div>

    </div>


<div>

</div>
</div>

    <div className=" ">
    
    </div>

</div>



 
<footer className="p-10 pt-[80vh]  footer bg-[#11131d] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
</div>
        </>
    )}else if(tab == 'loading'){
        return(
          <>

<div className=" bg-[#11131d] h-[110vh]">
<div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
             <div className="hero mt-[30vh]">
             <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
             <p className='mt-20 text-white'>Something Wrong? <span className='text-sky-600'><a href='https://twitter.com/skyex_me' target='_blank'>Tweet us</a></span> </p>
</div>
</div>
            </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default FProduct