import { useEffect, useState } from "react";
import axios from 'axios'
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import Darkfoot from "../darkfootbar";
const Register = ()=>{
  const [tab, settab] = useState('app')
  const [loadingtype, setloadingtype] = useState('Logging in')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [alart, setalart] = useState('')
    const [prog, setprog] = useState(0)
    const cookies = new Cookies();
    const current = new Date();
    const nextYear = new Date();
    const navigate = useNavigate();
useEffect(()=>{
  const userTokenc = cookies.get('userToken')
if(userTokenc){
  if(userTokenc.length > 10 && userTokenc.length < 401){
    settab('loading')
    setloadingtype('Logging in')
    axios.post('https://api.skyex.me/verfy',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
      // console.log(Response)
      if(Response){
        if(Response.data.status == 404){
          settab('app')
          setloadingtype('Registring')
        }else if(Response.data.status == 501){
          setloadingtype('Something went wrong please contact us')
          settab('loading')
        }
        else if(Response.data.status == 502){
          settab('loading')
          setloadingtype('Please Login again')
          setTimeout(() => {
            settab('app')
          }, 4000);
        }else if(Response.data.status == 200){
          setloadingtype('You have logged in succefuly')
          navigate('/portal', { replace: true })
          window.location.reload();
        }
      }
    })
      }else{
        settab('app')
    setloadingtype('Registring')
      }
}else{
  settab('app')
setloadingtype('Registring')
}

},[])
useEffect(()=>{
  if(tab == 'loading')
  window.scrollBy(0,-90000000);
},[tab])
const login = ()=>{
    if(prog == 0){
     setprog(1)
     if(email.length < 3){
         setprog(0)
         setalart('Type Your Email')
     }else if(password.length < 3){
        setprog(0)
        setalart('Type your password')
    }else{
        setprog(0)
        setalart('')
        settab('loading')
        setloadingtype('Logging in')
        axios.post('https://api.skyex.me/login',{username:email,password:password}).then((Response)=>{
            // console.log(Response.data)
            if(Response.data.status === 334){
                settab('app')
                setalart('Check your inputs')
            }else if(Response.data.status === 200){
                // in
                nextYear.setFullYear(current.getFullYear() + 80);
                const userToken = Response.data.userToken
                const username = Response.data.username
                cookies.set('userToken', userToken, {
                    path: '/',
                    expires: nextYear,
                });
                localStorage.setItem('username',username)
                setloadingtype('You have logged in succefuly')
                navigate('/portal', { replace: true })
                window.location.reload();
            }else if(Response.data.status === 334){
                settab('app')
                setalart('Wrong email/password')
            }else if(Response.data.status == 404){
              settab('app')
              setalart('Wrong email/password')
              setloadingtype('Registring')
            }
        })
    }
    }
}

    if(tab == 'app'){
     return (
        <>
                  <Helmet>
  <title>Skyex Login</title>
  <meta
      name="description"
    
      content="Skyex Login portal. don't have an account? Register here"
    />
    <meta name="twitter:card" content="summary" />
<meta name="twitter:site" content="@Skyex_web" />
<meta name="twitter:title" content="Skyex web hosting" />
<meta name="twitter:description" content="Skyex provides premium services for a budget price and a full team ready to provide support for you any time you need" />

</Helmet>
        <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

        </div>
        <div className="w-full bg-[#11131d] text-white">
     


<div className="hero">
    
<div className="p-10 mb-80 card bg-base-300 sm:max-w-96 md:w-96 mt-12 lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl ">
<div className="form-control mb-4 text-2xl -mt-2 text-center">
Login
  </div>

  <div className="form-control mb-2">
    <label className="label">
      <span className="label-text">Email</span>
    </label> 
    <input type="text" value={email} onChange={(e)=>{setemail(e.target.value.replace(/\s/g, ''))}} placeholder="hi@domain.com" className="input bg-base-200" />
  </div>
  <div className="form-control  mb-2">
    <label className="label">
      <span className="label-text">Password</span>
      
    </label> 
    <input type="password" value={password} onChange={(e)=>{setpassword(e.target.value.replace(/\s/g, ''))}} placeholder="password" className="input bg-base-200" />
    <label className="label">
    <span className="label-text-alt"><a href="/register">Register</a></span>
    <span className="label-text-alt">Forget password?</span>
  </label>
</div>

  <div className="form-control mt-2 mb-2">
    <button className="btn btn-active" role="button" onClick={login} aria-pressed="true">Login</button> 
  </div>
  
</div>

</div>

{alart.length > 0 && (
  <div className="hero">
<div className="alert w-64 hero m-4 -mt-[33rem]">
  <div className="">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2196f3" className="w-7 h-7 mx-2 ">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
    </svg> 
    <label>{alart}</label>
  </div>
</div>
</div>
)}




        </div>
<Darkfoot />
        </>
    )}else if(tab == 'loading'){
      return (
        <>
                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d] h-screen">
     


<div className="hero">
    
<div className="p-10 card bg-base-300 sm:w-64 md:w-96 mt-12 lg:max-w-2xl xl:max-w-2xl 2xl:max-w-2xl ">
<div className="form-control mb-4 text-2xl hero -mt-2 text-center">
{loadingtype}


  </div>
  <div className="hero">
  <button className="btn btn-lg bg-base-300 border-base-300 btn-circle loading"></button> 
  </div>
</div>
</div>

        </div>
        <Darkfoot />
        </>
    )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Register