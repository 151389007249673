import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import { useNavigate,useParams } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios'
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { PayPalScriptProvider, PayPalButtons,usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Pay = ()=>{

    let p = useParams()
    const invoice = p.id
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('CALL PIN')
    const [tab, settab] = useState('loading')
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)
    const [invoices, setinvoices] = useState([]);
    const [type, settype] = useState(0)
    const cookies = new Cookies();

  const [timed, settimed] = useState('')
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  moment.tz.setDefault(timezone);
    const createOrder = (data, actions) => {

        return actions.order
        .create({
            
          purchase_units: [
            {
              description: 'named',
              amount: {
                currency_code: 'USD',
                value: parseFloat(Object.values(invoices[0])[0]),
              },
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING"
        }
        })
  
        .then((orderID) => {
  
          return orderID;
        });
      



  };
  const onApprove = (data, actions) => {
    const userTokenc = cookies.get('userToken')
    return actions.order.capture().then(function (details) {
      const { payer } = details.purchase_units[0].amount;

      axios.post('https://api.skyex.me/pay',{
        username:localStorage.getItem('username'),
        order_id:details.id,
        payer_email:details.payer.email_address,
        payer_id:details.payer.payer_id,
        in_id:invoice
      }).then((Response)=>{
        console.log(Response);
        // settab('approved')
      })

    //   setpaid(details.purchase_units[0].amount.value + ' ' + details.purchase_units[0].amount.currency_code)
    //   setSuccess("has been paid successfully");

    });
  };
  const onError = (data, actions) => {
    // seterror("An Error occured with your payment ");
  };










const PaypalB = ()=>{
    const [{ isPending }] = usePayPalScriptReducer();

    return(
        <>
    {isPending ? <div className="">Loading...</div> : null}
    <PayPalButtons
        disabled={false}
        onClick={(createOrder=>{})}
        className='ss'
             createOrder={createOrder}
             onApprove={onApprove}
        />
        
        </>

    )
}



    useEffect(()=>{
      const userTokenc = cookies.get('userToken')
    if(userTokenc){
      if(userTokenc.length > 10 && userTokenc.length < 401){
        axios.post('https://api.skyex.me/invoice',{userToken:userTokenc,invoice:invoice,username:localStorage.getItem('username')}).then((Response)=>{
          console.log(Response)
          if(Response){
            if(Response.data.status == 404){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else if(Response.data.status == 501){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }
            else if(Response.data.status == 502){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else{
              // console.log(Response.data)
              setinvoices(Response.data)
                const gf = Response.data[0]
                const dateToFormat = gf.due_date;
                // console.log(moment.gmt(dateToFormat).local().format('HH:mm'))
                if(gf.order_id){
                  if(gf.amount){
                    if(parseFloat(gf.amount) == parseFloat(Object.values(gf)[0]) && gf.currncy == 'USD'){
//  paid
settype(3)
                    }else{
// wrong
settype(2)
                    }
              
                  }else{
// Verifying
settype(1)
                  }
              
                }else{
// Unpaid
settype(0)
                }
              

              
settab('app')
            }
          }
        })
          }else{
            navigate('/login', { replace: false })
            window.scrollBy(0,-90000000);
          }
    }else{
      navigate('/login', { replace: false })
      window.scrollBy(0,-90000000);
    }
    
    },[])
      if (tab == 'app'){

      
    return (
        <>
                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full h-screen bg-[#11131d] text-white">
  




<div className="sm:px-4  flex justify-center place-items-center">
    <div className="bg-[#111118] pb-16 max-w-3xl p-8 rounded-2xl grow">
<div className='text-center text-3xl'>Skyex</div>
<div className='mt-5 text-lg md:text-left sm:text-h text-gray-400'>
<div>Invoice to</div>
<div>{invoices[0].name}</div>
<div>Due Date</div>

<div><Moment add={{ hours: 3 }} format="YYYY/MM/DD">{invoices[0].due_date}</Moment></div>
<div><Moment add={{ hours: 3 }} format="HH:mm">{invoices[0].due_date}</Moment></div>
{timed}
</div>
{/* unpaid */}
{type == 0 && (
    <>
        <div className=' mt-5 md:hidden grid place-items-center'>
<div className='float-right opacity-[.85]  bg-red-700 p-4 rounded-xl sm:px-12 md:px-24 md:-mr-[drem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[6rem]'>
UNPAID
</div>
</div>
<div className='float-right opacity-[.85] sm:hidden md:block  bg-red-700 p-4 rounded-xl sm:px-12 md:px-20 -mr-[5.4rem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[12rem]'>
UNPAID
</div>
    </>
)}

{/* verf */}
{type == 1 && (
    <>
            <div className=' mt-5 md:hidden grid place-items-center'>
<div className='float-right opacity-[.85]  bg-orange-500 p-4 rounded-xl sm:px-12 md:px-24 md:-mr-[drem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[6rem]'>
Verifying
</div>
</div>
<div className='float-right opacity-[.85] sm:hidden md:block  bg-orange-500 p-4 rounded-xl sm:px-8 md:px-16 -mr-[4.4rem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[9.97rem]'>
Verifying
</div>
    </>
)}
{/* wrong */}
{type == 2 && (
    <>
    <div className=' mt-5 md:hidden grid place-items-center'>
<div className='float-right opacity-[.85]  bg-red-700 p-4 rounded-xl sm:px-12 md:px-24 md:-mr-[drem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[6rem]'>
WRONG PAYMENT
</div>
</div>
<div className='float-right opacity-[.85] sm:hidden md:block  bg-red-700 p-4 rounded-xl sm:px-12 md:px-24 -mr-[4.4rem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[6rem]'>
WRONG PAYMENT
</div>

    </>
)}
{/* paid */}
{type == 3 && (
    <>
    <div className=' mt-5 md:hidden grid place-items-center'>
<div className='float-right opacity-[.85]  bg-green-800 p-4 rounded-xl sm:px-12 md:px-24 md:-mr-[drem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[6rem]'>
Paid
</div>
</div>
<div className='float-right opacity-[.85] sm:hidden md:block  bg-green-800 p-4 rounded-xl sm:px-12 md:px-24 -mr-[4.4rem] sm:text-lg md:text-2xl sm:mt-[0.5rem]  md:rotate-45 md:-mt-[11rem]'>
Paid
</div>

    </>
)}









<div className='flex justify-center md:mt-16 sm:mt-[4rem]'>
    <table id='f1' className='bg-[#111118] text-lg  p-4'>
    <thead className='p-5 border-b border-gray-400'>
      <tr className='bg-[#111118] w-96 '>

        <th className='px-4 p-2'>Name</th>
        <th className='px-4 p-2'>price</th>
      </tr>
    </thead>
    <tbody className='p-5  '>
      <tr id='f1' className='w-full border-b border-gray-400 text-gray-200'>
        <th className='px-4  p-2'>{invoices[0].product_name}</th>
        <td className='px-4 p-2'>${parseFloat(Object.values(invoices[0])[0])}</td>
        </tr>
        <tr id='f1' className='w-full pt-96 text-gray-200'>
        <th className='px-4 p-2'></th>
        <td className='px-4 p-4'>Total</td>
        <td className='px-4 p-4'>${parseFloat(Object.values(invoices[0])[0])}</td>
        </tr>
      </tbody>

    </table>
</div>
<div>
{type == 0 && (
    <div className="form-control mt-12 hero">
    <form>
    <label className="label">
        {/* ARnatSVApw1Of30z2Iw9Vz2O12dKYaB3NJlcw7sO7TZwvPP5JsBtIC3VhFy2DL5kKz0DcM7NRdALga0v */}
    <PayPalScriptProvider deferLoading={false} options={{ "client-id": "ASUlPezWcvmucojYBaKsg0-9aEx7_Jk2L9NG53e_TTQu8Vfxqz2WTq3MHf-TDKRhwJuPBQo1gLhPvHMY" }}>
    {/* {isPending ? <div className="spinner" /> : null} */}


<PaypalB />

    </PayPalScriptProvider>
    </label> 
    </form>

  </div>
)}

    {/* paypal */}
    {/* <div className="mockup-code max-w-sm">
  <pre data-prefix="$"><code>npm i as</code></pre> 
  <pre data-prefix=">" className="text-warning"><code>installing...</code></pre> 
  <pre data-prefix=">" className="text-success"><code>Done!</code></pre>
</div> */}

</div>
    </div>
{/* <div className="flex">
<img src="https://i.ibb.co/74wfTt7/Pngtree-cat-default-avatar-5416936.png" className="w-36" /><div className="mt-4">SkyEx</div>
</div> */}
</div>


        </div>
<div className="sm:h-[35rem] md:h-[35rem] bg-[#11131d] w-screen">

</div>
        <footer className="p-10 mt-[] footer bg-[#11131d] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
        </>
    )}else if(tab == 'loading'){
        return(
            
            <>


            <div className=" bg-[#11131d] h-[110vh]">
            <div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">
            
            </div>
                         <div className="hero mt-[30vh]">
                         <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
            </div>
            </div>
              </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Pay