import { useNavigate, } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";
import react, { useEffect, useState } from "react";

import Darkfoot from "../darkfootbar";
const Jobs = ()=>{
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "https://forms.gle/oxGUM8sNH8yCmRiE9";
  

  }, [])
  
    return (
        <>
                                <Helmet>
          <title>Skyex | Jobs</title>
          <meta
      name="description"
    
      content="Skyex Jobs."
    />
        </Helmet>
             <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>

        <div className="w-full bg-[#11131d] text-white">
     








<div className="text-black text-2xl  hero">
<div id='c1' className="text-white shadow-2xl bg-base-300 m-8 max-w-lg p-6 rounded-lg">
<p id='f2' className="mb-5 hero">
      <Typewriter
  
  onInit={(typewriter)=> {

  typewriter
  //  .pasteString('Welcome to ')
  .typeString("Redirecting..")
  .start();
  }}
  />
          </p> 
</div>

</div>



        </div>
        <div className='h-[50vh] bg-[#11131d] w-screen'>
                </div>
<Darkfoot />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Jobs