import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Darkfoot from "../darkfootbar";
import { Helmet } from "react-helmet";
const Logout = ()=>{
    
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('CALL PIN')
    const [tab, settab] = useState('loading')
    const [username, setusername] = useState('')
    const [numi, setnumi] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)

    const cookies = new Cookies();
    useEffect(()=>{
        cookies.remove("userToken")
        localStorage.removeItem('username');
        navigate('/login', { replace: true })
        window.location.reload();
        window.scrollBy(0,-90000000);
      },[])
      if (1 == 1){

      
    return (
        <>
        <Helmet>
          <title>Skyex | portal</title>
          <meta
      name="description"
    
      content="Skyex Portal."
    />
        </Helmet>
                        <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d]">
  








<div className="text-white w-screen text-2xl hero">

<div className="p-10 grid sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center  ">
Logging out...
</div>



        </div>
        </div>
        <div className="h-[100vh] bg-[#11131d] ">

        </div>
        <Darkfoot />

        </>
    )}
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Logout