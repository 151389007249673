import react, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons,usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate,useParams } from 'react-router-dom'
import axios from "axios";
import Cookies from 'universal-cookie';
const OrderVps = ()=>{
  const navigate = useNavigate();
  let p = useParams()
  const plane = p.plane
  const [name, setname] = useState('')
  const [OwnerUser, setOwnerUser] = useState('')
  const [OwnerPassword, setOwnerPassword] = useState('')
  const [discription, setdiscription] = useState('')
  const [price, setprice] = useState('')
  const [is_sale, setis_sale] = useState('')
  const [sale_price, setsale_price] = useState('')
  const product = 'vps'  
  const [tab, settab] = useState('loading')
  const [id, setid] = useState(0);
  const [error, seterror] = useState('')
  const [prev, setprev] = useState([])
  const [alart, setalart] = useState('');
  const [info, setinfo] = useState(0);
  const [uknow, setuknow] = useState('');
  const [operating, setoperating] = useState('Ubuntu 20.04');
  const [ips, setips] = useState('1');
  const [bandwidth, setbandwidth] = useState('1TB');
  // const [{ isPending }] = usePayPalScriptReducer();
// useEffect(()=>{console.log(isPending)},[isPending])
 
  const cookies = new Cookies();
  let named = product+' - '+ plane
  const createOrder = (data, actions) => {

        return actions.order
        .create({
            
          purchase_units: [
            {
              description: named,
              amount: {
                currency_code: 'USD',
                value: parseFloat(price),
              },
            },
          ],
          application_context: {
            shipping_preference: "NO_SHIPPING"
        }
        })
  
        .then((orderID) => {
  
          return orderID;
        });
      



  };

  const onApprove = (data, actions) => {
    const userTokenc = cookies.get('userToken')
    return actions.order.capture().then(function (details) {
      const { payer } = details.purchase_units[0].amount;

      axios.post('https://api.skyex.me/create_order',{
        username:localStorage.getItem('username'),
        order_id:details.id,
        payer_email:details.payer.email_address,
        payer_id:details.payer.payer_id,
        userToken: userTokenc,id:id,
        data:JSON.stringify({domain:name,notes:OwnerPassword,operating:operating}),
        r:localStorage.getItem('r'),
      }).then((Response)=>{
        // console.log(Response);
        settab('approved')
      })

    //   setpaid(details.purchase_units[0].amount.value + ' ' + details.purchase_units[0].amount.currency_code)
    //   setSuccess("has been paid successfully");

    });
  };
  const onError = (data, actions) => {
    seterror("An Error occured with your payment ");
  };




const PaypalB = ()=>{
    const [{ isPending }] = usePayPalScriptReducer();

    return(
        <>
    {isPending ? <div className="">Loading...</div> : null}
    <PayPalButtons
        disabled={false}
        onClick={(createOrder=>{})}
        className='ss'
             createOrder={createOrder}
             onApprove={onApprove}
        />
        
        </>

    )
}


  useEffect(()=>{
    const userTokenc = cookies.get('userToken')
  if(userTokenc){
    if(userTokenc.length > 10 && userTokenc.length < 401){
        settab('loading')

      axios.post('https://api.skyex.me/verfy',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
        // console.log(Response)
        if(Response){
          if(Response.data.status == 404){
            cookies.remove("userToken")
            navigate('/login', { replace: true })
            window.location.reload();
            window.scrollBy(0,-90000000);
          }else if(Response.data.status == 501){
            seterror('Something went wrong please contact us')
            settab('loading')
          }
          else if(Response.data.status == 502){
            settab('loading')
            setTimeout(() => {
              settab('app')
            }, 4000);
          }else if(Response.data.status == 200){
            axios.post('https://api.skyex.me/info',{plane:plane,product:product}).then((Response)=>{
      
                // console.log(Response)
              if(Response.data.status == 501){
                  seterror('Something Wrong happened refresh please the page')
                  setTimeout(() => {
                      settab('app')
                  }, 600);
              }else if(Response.data.length == 0){
                  // go to 404
                  navigate('/404', { replace: false })
              }else if(Response.data.length == 1){
                //   setname(Response.data[0].name)
                  setdiscription(Response.data[0].description)
                  setprice(Response.data[0].price)
                  setis_sale(Response.data[0].is_sale)
                  setsale_price(Response.data[0].sale_price)
                  setid(Response.data[0].id)
                  setprev(JSON.parse(Response.data[0].api1))

                      settab('app')

          
              }else{

                      settab('app')

              }  
            }).catch((error)=>{
              // console.log(error)
              setTimeout(() => {
                  settab('app')
              }, 600);
            //   seterror("We can't access our api please check your firewall or contact your network administrator")
            })




          }
        }
      })
        }else{
            cookies.remove("userToken")
            navigate('/login', { replace: true })
            window.location.reload();
            window.scrollBy(0,-90000000);
        }
  }else{
         navigate('/register', { replace: false })
  }

  },[])





  if(tab == 'app'){
    return (
        <>
                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d]">
     





          {error.length > 0 && (
  <>
<center>
<div className="hero w-auto flex mt-8 mx-4 max-w-xl justify-center">
<img alt='404' className='hero' src={'https://www.jea.com/cdn/images/backgrounds/404/404-bg-img.svg'} />
</div>

</center>
<div id='f2' className='hero w-screen p-2 text-center text-black'>{error}
                        </div>
  </>
        
)}

{error.length == 0 && (
<div className='w-screen hero'>
<div className="p-10 grid sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">
<div className="form-control">
<div className="text-xl hero" id='f2'>
Summary
</div>
<div id='c2' className="sm:w-auto md:w-[20rem] p-4 mb-2 h-auto rounded-xl mt-4">
<ol>
    <li className='text-xl text-center '>
         {plane}
    </li>

<li className='text-center mt-2 text-2xl'>${price}</li>
</ol>
    </div>
  </div>
<div className="text-xl" id='f2'>
Configure
</div>

  <div className="form-control">
    <label className="label">
      <span className="label-text">Domain: (optional)</span>
    </label> 
    <input type="text" value={name} onChange={(e)=>{setname(e.target.value)}} placeholder="domain.com" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
    <label className="label">
      <span className="label-text">Operating System:</span>
    </label> 
    <select className="select bg-base-200 sm:w-[12rem] md:w-[20rem]" value={operating} onChange={(e)=>{setoperating(e.target.value)}}>
    <option selected="selected">Ubuntu 20.04</option>
  <option >Ubuntu 18.04</option>
  <option>Debian 10</option>
  <option>CentOS 7</option>
  <option>Windows Server 2019</option>
  <option>Windows Server 2012</option>
    </select>
  </div>


  <div className="form-control">
    <label className="label">
      <span className="label-text">Notes:</span>
    </label> 
    <input type="text" value={OwnerPassword} onChange={(e)=>{setOwnerPassword(e.target.value)}} placeholder="Extra cheese, more bandwidth" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>  
  <div className="text-left mt-3 text-xs flex">
      <div>
      <input type="checkbox" checked className="checkbox -mb-1.5 checkbox-md" />
      </div>
 <p className=" ml-2 mt-1 ">I agree to <span className="underlsine underldine-offset-4" onClick={()=>{
 }}>Terms of use</span> & <span className="undersline undedrline-offset-4" onClick={()=>{
}}>Privacy policy</span></p>
  </div>

  {/* paypal  */}
  <div className="form-control mt-4 hero">
    <form>
    <label className="label">
        {/* ARnatSVApw1Of30z2Iw9Vz2O12dKYaB3NJlcw7sO7TZwvPP5JsBtIC3VhFy2DL5kKz0DcM7NRdALga0v */}
    <PayPalScriptProvider deferLoading={false} options={{ "client-id": "ASUlPezWcvmucojYBaKsg0-9aEx7_Jk2L9NG53e_TTQu8Vfxqz2WTq3MHf-TDKRhwJuPBQo1gLhPvHMY" }}>
<PaypalB />
    </PayPalScriptProvider>
    </label> 
    </form>

  </div>
</div>


</div>

)}

{uknow.length > 0 && (
  <div className="hero">
<div className="alert w-64 hero m-4">
  <div className="">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2196f3" className="w-7 h-7 mx-2 ">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>                          
    </svg> 
    <label>{uknow}</label>
  </div>
</div>
</div>
)}

<footer className="p-10  footer bg-[#11131d] mt-[20vh] text-base-content">
  <div>
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current">
      <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
    </svg> 
    <p>SkyEx Ltd.
      <br />Managed by omar
    </p>
  </div> 
  <div>
  
  <span className="footer-title">Docs</span> 

  <a className="link link-hover" href="https://blog.skyex.me/">Skyex Blog</a> 
</div>
  <div>
    <span className="footer-title">Company</span> 
    <a onClick={()=>{
       navigate('/aboutus', { replace: false })
       window.scrollBy(0,-90000000);
    }} 
    className="link link-hover">About us</a> 
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    <a onClick={()=>{
       navigate('/jobs', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Jobs</a> 
    {/* <a className="link link-hover">Press kit</a> */}
  </div> 
  <div>
  
    <span className="footer-title">Legal</span> 
    <a className="link link-hover" onClick={()=>{
       navigate('/terms', { replace: false })
       window.scrollBy(0,-90000000);}}>Terms of use</a> 
    <a className="link link-hover">Privacy policy</a> 
    <a onClick={()=>{
       navigate('/cookiepolicy', { replace: false })
       window.scrollBy(0,-90000000);}} className="link link-hover">Cookie policy</a>
  </div>
<div>


    <span className="footer-title">Social</span> 
    <div className="grid grid-flow-col gap-4">
      <a className='-mt-1' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-2xl fa-instagram"></i>
      </a>
      <a className='-mt-1' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-2xl"></i>
      </a>
      <a href='https://twitter.com/skyex_me' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
      {/* <a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
        </svg>
      </a>  */}
    </div>
    </div>
</footer>
        </div>
        </>
    )}else if(tab == 'loading'){
        return(
            <>


            <div className=" bg-[#11131d] h-[110vh]">
            <div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">
            
            </div>
                         <div className="hero mt-[30vh]">
                         <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
            </div>
            </div>
              </>
          )
    }
    else if(tab == 'approved'){
        return(
            <>
            <div className="hero">
            <div className="p-10 grid py-32 sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-200">


<div className="wrapper"> <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
       <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
       <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
   </svg>
</div>
<div className="text-xl text-white">
    Thank You
</div>
<div className="text-gray-400">You Have Paid ${price} Successfully</div>
<div className="text-gray-400 text-center">The payment will appere soon</div>
</div>

            </div>


      </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default OrderVps