import react, { useEffect,useState } from "react";
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios'
const NavBar = ()=>{
  const [navtype, setnavtype] = useState(0)
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userTokenc = cookies.get('userToken')
const [avatat, setavatat] = useState("/logo.png")

  useEffect(()=>{
    if(userTokenc){
      setnavtype(1)
      if(userTokenc.length > 10 && userTokenc.length < 401){
        axios.post('https://api.skyex.me/verfy',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
          // console.log(Response)
          if(Response){
            if(Response.data.status == 404){
              setnavtype(0)
              cookies.remove("userToken")
            }else if(Response.data.status == 501){
              setnavtype(0)
              cookies.remove("userToken")
            }
            else if(Response.data.status == 502){
              setnavtype(0)
              cookies.remove("userToken")
            }else if(Response.data.status == 200){
              setnavtype(1)
            }
          }
        })
          }else{
            setnavtype(0)
          }
    }


  },[])
  if(navtype == 0){

  // https://1.api.o4mr.tk/1128586260856139548879091.jpg
    return (
        <>

<div className="navbar mb-2 shadow-lg p-4 bg-neutral w-screen text-neutral-content rounded-box">
  <div className="flex-none flex">
  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none flex">
    <button className="btn btn-square  btn-ghost -mb-1">
    {/* <i className="fas fa-terminal text-xl -ml-3 "></i> */}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">           
        <path strokeLinecap="round" strokelinecapstrokelinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>               
      </svg>
    </button>
  </div> 

</div>

  <ul tabIndex="0" className="p-2 shadow menu mt-12 dropdown-content bg-base-300 -mr-[14.2rem] rounded-box w-52">
    <li className="text-center text-lg text-gray-200 mt-1">Hosting</li>
    <li className=" w-[12.3rem]">
      <a onClick={()=>{
       navigate('/start/minecraft', { replace: false })
       window.scrollBy(0,-90000000);}}><i className="fa-solid fa-gamepad mr-2 -ml-1"></i>Minecraft Servers</a>
    </li> 
    <li className="">
      <a onClick={()=>{
       navigate('/start/blog', { replace: false })
       window.scrollBy(0,-90000000);}}><i className="fa-solid fa-blog mr-2 -ml-1"></i>Blog Hosting</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/webhost', { replace: false })
              window.scrollBy(0,-90000000);
    }} > <i className="fa-solid fa-laptop-code mr-2 -ml-[0.46rem]"></i>Web Hosting</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/start/vps', { replace: false })
              window.scrollBy(0,-90000000);
    }} ><i className="fa-solid fa-server mr-[0.64rem] -ml-[0.3rem]"></i> VPS Hosting</a>
    </li> 


  </ul>
</div>
  </div> 
  <div className="flex-1 px-2 mx-2 ">
    <div className="flex">
{/*     <i className="fas fa-coffee inline-block text-xl mt- mr-3"> </i> */}
<div className="text-bold inline-block -ml-5 -mb-1 cursor-pointer	" onClick={() =>{navigate('/', { replace: false })}}>Skyex</div>

    </div>

  </div> 


  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
  {/* <div className="flex-none">
    <button className="btn btn-square btn-ghost">
    <i className="fas fa-coffee inline-block text-2xl mr-2"></i>
    </button>
  </div>  */}

</div>
  <ul tabIndex="0" className="p-4 shadow menu mt-12 dropdown-content bg-base-200 -mr-12 rounded-box w-64">
    <li>
      <a>     
      <i className="far fa-bell text-xl mr-4 -ml-3"></i>  Welcome To Our community</a>
    </li> 
 <li className='text-center text-cyan-600 mt-1'>
Show All
 </li>
  </ul>
</div>

  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
    <div className="flex-none">
    <div className="avatar">
      <div className="rounded-full w-14 h-14 m-1">
        <img alt='logo' src={avatat} />
      </div>
    </div>
  </div>
</div>
{/* avatat */}
  <ul tabIndex="0" className="p-2 shadow menu mt-12 dropdown-content bg-base-300 -mr-6 rounded-box w-52">
    <li onClick={() =>{navigate('/login', { replace: false })}}>
      <a>Login</a>
    </li> 
    <li onClick={() =>{navigate('/register', { replace: false })}}>
      <a>Register</a>
    </li> 
    <li className='text-sky-500'>
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    </li>
  </ul>
</div>

</div>

        </>
    )}else if(navtype == 1){
      return (
        <>

<div className="navbar -mt-[0.8rem] mb-2 shadow-lg p-4 bg-neutral w-screen text-neutral-content rounded-box">












  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none flex">
    <button className="btn btn-square  btn-ghost -mb-1">
    {/* <i className="fas fa-terminal text-xl -ml-3 "></i> */}
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">           
        <path strokeLinecap="round" strokelinecapstrokelinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>               
      </svg>
    </button>
  </div> 

</div>

  <ul tabIndex="0" className="p-2 shadow menu mt-12 dropdown-content bg-base-300 -mr-[14.2rem] rounded-box w-52">
    <li className="text-center text-lg text-gray-200 mt-1">Hosting</li>
    <li className=" w-[12.3rem]">
      <a onClick={()=>{
       navigate('/start/minecraft', { replace: false })
       window.scrollBy(0,-90000000);}}><i className="fa-solid fa-gamepad mr-2 -ml-1"></i>Minecraft Servers</a>
    </li> 
    <li className="">
      <a onClick={()=>{
       navigate('/start/blog', { replace: false })
       window.scrollBy(0,-90000000);}}><i className="fa-solid fa-blog mr-2 -ml-1"></i>Blog Hosting</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/webhost', { replace: false })
              window.scrollBy(0,-90000000);
    }} > <i className="fa-solid fa-laptop-code mr-2 -ml-[0.46rem]"></i>Web Hosting</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/start/vps', { replace: false })
              window.scrollBy(0,-90000000);
    }} ><i className="fa-solid fa-server mr-[0.64rem] -ml-[0.3rem]"></i> VPS Hosting</a>
    </li> 


  </ul>
</div>


  <div className="flex-1 px-2 mx-2 ">
    <div className="flex">
{/*     <i className="fas fa-coffee inline-block text-xl mt- mr-3"> </i> */}
  <div className="text-bold inline-block -ml-5 -mb-1 cursor-pointer	" onClick={() =>{navigate('/', { replace: false })}}>Skyex</div>

    </div>

  </div> 



  <div className="dropdown dropdown-left  flex-none">
  <div tabIndex="0" className=""> 
  <div className="flex-none ">
    <button className="btn btn-square btn-ghost ">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-7 h-7 stroke-current">     
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>                     
      </svg>
    </button>
  </div> 

</div>
  <ul tabIndex="0" className="p-4 shadow menu mt-12  dropdown-content bg-base-200  -mr-12 rounded-box w-64">
    <li>
      <a>     
      <i className="far fa-bell text-xl mr-4 -ml-3"></i>  Welcome To Our community</a>
    </li> 

 <li className='text-center text-cyan-600 mt-1'>
Show All
 </li>
  </ul>
</div>

  <div className="dropdown dropdown-left flex-none">
  <div tabIndex="0" className=""> 
    <div className="flex-none">
    <div className="avatar">
      <div className="rounded-full w-14 h-14 m-1">
        <img alt='logo' src={avatat} />
      </div>
    </div>
  </div>
</div>
  <ul tabIndex="0" className="p-2 shadow menu mt-12 dropdown-content bg-base-300 -mr-6 rounded-box w-52">
    <li>
      <a onClick={()=>{
       navigate('/myaccount', { replace: false })
       window.scrollBy(0,-90000000);}}>My Account</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/portal', { replace: false })
              window.scrollBy(0,-90000000);
    }} >Portal</a>
    </li> 
    <li>
      <a onClick={()=>{
              navigate('/portal/invoices', { replace: false })
              window.scrollBy(0,-90000000);
    }} >Invoices</a>
    </li> 

    <li className='text-sky-500'>
      <a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a>
    </li>
    <li onClick={()=>{
              navigate('/logout', { replace: true })
    }} className='text-red-600'>
      <a>Logout</a>
    </li>
  </ul>
</div>

</div>

        </>
    )
    }
}
export default NavBar