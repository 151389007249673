import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Darkfoot from "../darkfootbar";
import { Helmet } from "react-helmet";
const Giveaway = ()=>{
    
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('SUPPORT PIN')
    const [tab, settab] = useState('app')
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)
    const [error, seterror] = useState('');
    const cookies = new Cookies();

      if (tab == 'app'){

      
    return (
        <>
        <Helmet>
          <title>Skyex Giveaway</title>
          <meta
      name="description"
    
      content="Skyex | My account. Edit your account. Get your support pin. Change password"
    />
        </Helmet>
                                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d]">
  








<div className="text-white w-screen text-2xl hero">

<div className="p-10 grid sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">
<div id='f5' className='text-4xl'>
Skyex Giveaway
</div>
<div className='text-xl mt-4 max-w-96 text-center'>
Givaway on minecraft server 16GB or 8GB vps
    </div>  
  <div className="form-control mt-5">
    <label className="label">
      <span className="label-text">Name</span>
    </label> 
    <input type="text" value={name} onChange={(e)=>{setname(e.target.value)}} placeholder="omar" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
    <label className="label">
      <span className="label-text">Email</span>
    </label> 
    <input type="text" value={email} onChange={(e)=>{setemail(e.target.value.replace(/\s/g, ''))}} placeholder="omar@skyex.me" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>
  <div className="form-control">
    <label className="label">
      <span className="label-text " >Discord (optional)</span>
    </label> 
    <input type="text" value={username} onChange={(e)=>{setusername(e.target.value)}} placeholder="NICKE#7220" className="input bg-base-200 sm:w-auto md:w-[20rem]" />
  </div>

  <div className="form-control">
  {/* <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]">Change Password</button> 
  <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]">Change Token</button>  */}
  <button className="btn btn-outline mt-3 sm:w-auto md:w-[20rem]" onClick={()=>{
                    settab('loading')
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
    console.log(true)
    seterror('')
    if(3 == 3){
        seterror('')
        if(name.length > 2){
            seterror('')
            axios.post('https://api.skyex.me/giveaway',{name:name,email:email,discord:username}).then((Response) =>{
                console.log(Response)
                if(Response.data.status == 200){
                    settab('approved')
                }else if(Response.data.status == 201 || Response.data.status == 202){
                    settab('app')
                    seterror('Email Already Exists')
                }else{
                    settab('app')
                    seterror('somthing went wrong Please contact support')
                }
            })
        }else{
            settab('app')
            seterror('Invalid name!')
        }
    }else{
        settab('app')
        seterror('Invalid Username!')

    }
  }else{
    console.log(false)
    settab('app')
    seterror('Invalid email address!')
  }

//   seterror('')
}}>ENTER</button> 

{/* <button type="submit" className="btn bg-base-300 mt-3 sm:w-auto md:w-[20rem]">Save</button> */}

  </div>
  
</div>


</div>
<center>
    {error.length > 0 && (
        <div className="sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md mt-5 lg:max-w-lg grid place-items-center">
<div class="alert alert-warning shadow-lg rid place-items-center">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
    <span className='ml-2'>{error}</span>
  </div>
</div>
</div>
    )}


</center>
<center>
<div className="sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md mt-5 lg:max-w-lg grid place-items-center">


    <div class="alert alert-info shadow-lg">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current flex-shrink-0 w-6 h-6 mt-3"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <span className='ml-3 text-left'>We are gonna announce the winner on our discord server and send him an email.</span>
  </div>
</div>
<div className='grid place-content-center'>
<a className='mt-3' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-6xl"></i>
      </a>
    </div>
</div>
</center>


        </div>

        <div className="h-[30vh] w-screen bg-[#11131d]">

        </div>
<Darkfoot />
        </>
    )}else if(tab == 'loading'){
        return(
            <>
<div className=" bg-[#11131d] h-[110vh]">
<div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
             <div className="hero mt-[30vh]">
             <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
      
</div>
</div>
      </>
          )
    }
    else if(tab == 'approved'){
        return(
            <>
            <div className=" -mt-[6.53rem]  bg-[#11131d] h-[110vh]">
                <div className="bg-[#11131d] w-screen h-36">

                </div>
            <div className="hero ">
            <div className="p-10 grid py-32 sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center card bg-base-300">


<div className="wrapper mb-5"> <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
       <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
       <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
   </svg>
</div>
<div className="text-xl text-center text-white">
    Thank You, verification required
</div>
<div className="text-gray-400 text-center mt-2">We have sent You an email to confirm your email</div>
</div>

            </div>
            </div>


      </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Giveaway