import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Darkfoot from "../darkfootbar";
import { Helmet } from "react-helmet";
const Portal = ()=>{
    
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('CALL PIN')
    const [tab, settab] = useState('loading')
    const [username, setusername] = useState('')
    const [numi, setnumi] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)

    const cookies = new Cookies();
    useEffect(()=>{
        const userTokenc = cookies.get('userToken')
      if(userTokenc){
        if(userTokenc.length > 10 && userTokenc.length < 401){
          settab('loading')
          axios.post('https://api.skyex.me/count',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
            
            
            if(Response){
              if(Response.data.status == 404){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              
              }else if(Response.data.status == 501){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              }
              else if(Response.data.status == 502){
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
              }else{
                // setloadingtype('You have logged in succefuly')
                setnumi(Response.data)
                settab('app')
              }
            }
          })
            }else{
                navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
            }
      }else{
        navigate('/login', { replace: false })
                window.scrollBy(0,-90000000);
      }
      
      },[])
      if (1 == 1){

      
    return (
        <>
        <Helmet>
          <title>Skyex | portal</title>
          <meta
      name="description"
    
      content="Skyex Portal."
    />
        </Helmet>
                        <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d]">
  








<div className="text-white w-screen text-2xl hero">

<div className="p-10 grid sm:w-auto md:w-[40rem] sm:max-w-xs md:max-w-md lg:max-w-lg place-items-center  card bg-base-300">
<div id='f5'>
    Portal
</div>
<div className="grid">
<div onClick={()=>{
                    navigate('/portal/products', { replace: false })
                    window.scrollBy(0,-90000000);
}} id='c1' className="rounded-2xl cursor-pointer	 p-3 h-40 mt-5 text-center bg-base-300 border-base-200 border">
    <div className="mt-10">
    <div className="-mt-3">Products</div>
    {tab == 'loading' && (
      <>
      <div className='mt-3'><button className="btn btn-lg btn-square loading bg-base-300 border-base-300 -mt-4"></button> </div>
<div className='text-right mr-3 -mt-4'>
<i className="fas fa-arrow-right"></i>
</div>
      </>
    )}
    {tab == 'app' && (
      <>
      <div className='mt-3'>{numi[1].gf}</div>
<div className='text-right mr-3'>
<i className="fas fa-arrow-right"></i>
</div>
      </>
    )}
    </div>

</div>
<div onClick={()=>{
                navigate('/portal/invoices', { replace: false })
                window.scrollBy(0,-90000000);
}} id='c1' className="rounded-2xl cursor-pointer border-base-200 border	 p-3 h-40 mt-5 text-center bg-base-300">
    <div className="mt-10">
    <div className="-mt-3">Invoices</div>
    {tab == 'loading' && (
      <>
      <div className='mt-3'><button className="btn btn-lg btn-square loading bg-base-300 border-base-300 -mt-4"></button> </div>
<div className='text-right mr-3 -mt-4'>
<i className="fas fa-arrow-right"></i>
</div>
      </>
    )}
    {tab == 'app' && (
      <>
      <div className='mt-3'>{numi[0].gf}</div>
<div className='text-right mr-3'>
<i className="fas fa-arrow-right"></i>
</div>
      </>
    )}
    </div>

</div>

</div>
  
</div>

    <div className=" ">
    
    </div>

</div>



        </div>
        <div className="h-[30vh] bg-[#11131d] ">

        </div>
        <Darkfoot />

        </>
    )}
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Portal