import react, { useEffect, useState } from "react";
import FootBar from "./../footbar";
import NavBar from "./../navbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Cookies from 'universal-cookie';
import axios from 'axios'
import Moment from 'react-moment';
import Darkfoot from "../darkfootbar";
const Invoices = ()=>{
    // useEffect(() => {
    //   axios.get('https://eschool.zuis.edu.jo/API/api/Questions/GetMultipleChoiceAnswersByQuestionID?QuestionID=22488').then((Response)=>{console.log(Response)})
    // }, [])
    
  const navigate = useNavigate();
  const [avatat, setavatat] = useState("/logo.png")
  const [callpin, setcallpin] = useState('CALL PIN')
    const [tab, settab] = useState('loading')
    const [username, setusername] = useState('')
    const [email, setemail] = useState('')
    const [name, setname] = useState('')    
    const [prog, setprog] = useState(0)
    const [invoices, setinvoices] = useState([]);
    const cookies = new Cookies();
    useEffect(()=>{
      const userTokenc = cookies.get('userToken')
    if(userTokenc){
      if(userTokenc.length > 10 && userTokenc.length < 401){
        axios.post('https://api.skyex.me/invoices',{userToken:userTokenc,username:localStorage.getItem('username')}).then((Response)=>{
          // console.log(Response)
          if(Response){
            if(Response.data.status == 404){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else if(Response.data.status == 501){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }
            else if(Response.data.status == 502){
              navigate('/login', { replace: false })
              window.scrollBy(0,-90000000);
            }else{
              // console.log(Response.data)
              setinvoices(Response.data)
settab('app')
            }
          }
        })
          }else{
            navigate('/login', { replace: false })
            window.scrollBy(0,-90000000);
          }
    }else{
      navigate('/login', { replace: false })
      window.scrollBy(0,-90000000);
    }
    
    },[])
      if (tab == 'app'){

      
    return (
        <>
                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full text-white bg-[#11131d] ">
  







        <div className="text-white w-screen  hero">

<div className="p-4 grid  place-items-center ">
<div id='f5' className='text-2xl mb-4'>
    Invoices
</div>
<div className="grid">

<div className="overflow-x-auto ">
  <table className="table md:w-[40rem]">
    <thead>
      <tr>
        <th>ID</th> 

        <th>price</th> 
        <th>Status</th> 
        <th>Due Date</th>
        <th>View</th>
      </tr>
    </thead> 
    
    <tbody>
{invoices.map((gf,i)=>{
  console.log(gf)
  const dateToFormat = gf.due_date;
  if(gf.order_id){
    if(gf.amount){
      if(parseFloat(gf.amount) == parseFloat(Object.values(gf)[0]) && gf.currncy == 'USD'){
        return(
          <tr key={gf.order_id} className="">
          <th>{i +1 }</th> 
          <td>{Object.values(gf)[0]} USD</td> 
          <td><div className="badge badge-accent">Paid</div> </td> 
          {/* <td><div className="badge bg-red-700 border-red-700 badge-accent">UNPAID</div> </td>  */}
          {/* <td><div className="badge bg-red-700 border-red-700 badge-accent">WRONG PAYMENT</div> </td>  */}
          <td>  <Moment format="YYYY/MM/DD">{dateToFormat}</Moment>
        </td>
          <td className='text-sky-600 cursor-pointer'  onClick={()=>{navigate('/portal/pay/' + gf.id, { replace: false })}}>View</td>
        </tr>
        )
      }else{
        return(
          <tr className="">
          <th>{i +1 }</th> 
          <td>{Object.values(gf)[0]} USD</td> 
          {/* <td><div className="badge badge-accent">Paid</div> </td>  */}
          <td><div className="badge bg-red-700 border-red-700 badge-accent">WRONG PAYMENT</div> </td> 
          <td>  <Moment format="YYYY/MM/DD">{dateToFormat}</Moment>
        </td>
          <td className='text-sky-600 cursor-pointer'><a href='https://discord.gg/KtZhay6wf6' target="_blank">Support</a></td>

        </tr>
        )
      }

    }else{
      return(
        <tr className="">
        <th>{i +1 }</th> 
        <td>{Object.values(gf)[0]} USD</td> 
        <td><div className="badge bg-orange-500 border-orange-500 badge-accent">Verifying</div> </td> 
        {/* <td><div className="badge bg-red-700 border-red-700 badge-accent">UNPAID</div> </td>  */}
        {/* <td><div className="badge bg-red-700 border-red-700 badge-accent">WRONG PAYMENT</div> </td>  */}
        <td>  <Moment format="YYYY/MM/DD">{dateToFormat}</Moment>
      </td>
        <td className='text-sky-600 cursor-pointer' onClick={()=>{navigate('/portal/pay/' + gf.id, { replace: false })}}>View</td>
      </tr>
      )
    }

  }else{
    return(
      <tr className="">
      <th>{i +1 }</th> 
      <td>{Object.values(gf)[0]} USD</td> 
      <td><div className="badge bg-red-700 border-red-700 badge-accent">UNPAID</div> </td> 
      {/* <td><div className="badge bg-red-700 border-red-700 badge-accent">WRONG PAYMENT</div> </td>  */}
      <td>  <Moment format="YYYY/MM/DD">{dateToFormat}</Moment>
    </td>
      <td className='text-sky-600 cursor-pointer' onClick={()=>{navigate('/portal/pay/' + gf.id, { replace: false })}}>Pay</td>
                {/*  */}
    </tr>
    )
  }

})}


    </tbody>
  </table>
  {invoices.length == 0 && (
        <div className="text-center hero flex justify-center">
        <h2 id='f1' className="text-black mt-2 text-center hero">You don't have any invoices</h2>

        </div>
      )}
</div>

</div>
  
</div>

    <div className=" ">
    
    </div>

</div>



        </div>
        <div className="h-[30vh] w-screen bg-[#11131d]">

        </div>
<Darkfoot/>
        </>
    )}else if(tab == 'loading'){
        return(
          <>
<div className=" bg-[#11131d] h-[110vh]">
<div className="w-full h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
             <div className="hero mt-[30vh]">
             <button className="btn btn-square bg-[#11131d] btn-lg border-[#11131d] text-2xl loading"></button>
      
</div>
</div>
        </>
          )
    }
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Invoices