import { useNavigate,useParams } from 'react-router-dom'
import react, { useEffect, useState } from "react";
const Ra7 = ()=>{

    let p = useParams()

    const navigate = useNavigate();
    useEffect(() => {
    localStorage.setItem('r',p.r)
    navigate('/', { replace: false })
    console.log(p.r)
    window.location.reload();
    }, [])
    

    return(
        <>
        Redirecting</>
    )
}
export default Ra7