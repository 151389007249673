import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import Darkfoot from "../darkfootbar";
const GetStarted = ()=>{
  const navigate = useNavigate();
    return (
        <>
                <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>
        <div className="w-full bg-[#11131d]  text-white">
            
     
<div className="w-screen h-16 bg-[#11131d]">

</div>
<div className="flex hero  flex-col w-full">

  <div id='f2' className="hero text-xl text-white -mt-4">
          <Typewriter
 
  onInit={(typewriter)=> {

  typewriter
   
  .typeString("Chosoe A Product")

  .start();
  }}
  />
          </div>
          <center>

          <div className="grid justify-center justify-items-center sm:grid-cols-1 md:grid-cols-3">
          {/* <div  className="m-8 sm:mt-16 md:mt-8">
<div className="card text-center shadow-2xl bg-base-200 m- max-wg-l">

<div className="card-body">
  <h2 className="card-title">Store</h2> 
  <p>Best for growing businesses selling online or in-store.</p> 
  <div className="justify-center card-actions">
    <button className="btn btn-outline btn-accent">MORE INFO</button>
  </div>
</div>
</div> 
</div> */}

<div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">Minecraft servers</h2> 
  <p>Play Minecraft with your friends on skyex servers with fast speeds and no lag.</p> 
  <p>

  </p>

  <div className="justify-center flex hero card-actions">
  <button onClick={()=>{ navigate('/start/minecraft', { replace: false }) 
  window.scrollBy(0,-90000000);}} className="btn btn-outline btn-accent">MORE INFO</button>
  </div>
</div>

</div> 
<div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">Blog</h2> 
  <p>Have Something intersting to share? that's your way.</p> 
  <div className="justify-center card-actions">
    <button className="btn btn-outline btn-accent"  onClick={()=>{ navigate('/start/blog', { replace: false })
  window.scrollBy(0,-90000000);}}>MORE INFO</button>
  </div>
</div>

</div> 
<div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">Web Hosting</h2> 
  <p>Developer and looking for a hosting? You came to the right place.</p> 
  <p>

  </p>

  <div className="justify-center flex hero card-actions">
    <button onClick={()=>{ navigate('/webhost', { replace: false })
  window.scrollBy(0,-90000000);}} className="btn btn-outline btn-accent">MORE INFO</button>
  </div>
</div>

</div> 
<div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">VPS</h2> 
  <p>Developer and looking for a hosting? You came to the right place.</p> 
  <p>

  </p>

  <div className="justify-center flex hero card-actions">
    <button onClick={()=>{ navigate('/start/vps', { replace: false }) 
  window.scrollBy(0,-90000000);}} className="btn btn-outline btn-accent">MORE INFO</button>
  </div>
</div>

</div>

 
<div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">Landing pages</h2> 
  <p>Details for this product is going to be available soon .</p> 
  <p>

  </p>

  <div className="justify-center flex hero card-actions">
  <button onClick={()=>{ navigate('/start/landing', { replace: false }) 
  window.scrollBy(0,-90000000);}} className="btn btn-outline btn-accent">COMING SOON</button>
  </div>
</div>

</div> 
          <div className="card text-center shadow-2xl bg-base-300 m-8 max-w-lg">

  <div className="card-body">
    <h2 className="card-title">Online Store</h2> 
    <p>Secure seo friendly Store for you businesses.</p> 
    <p>

    </p>
  
    <div className="justify-center flex hero card-actions">
    <button className="btn btn-outline btn-accent">COMING SOON</button>

    </div>
  </div>
  
</div> 







{/* <div className="card justify-self-center md:col-span-3 text-center shadow-2xl bg-base-200 m-8 max-w-lg">

<div className="card-body">
  <h2 className="card-title">Cloud</h2> 
  <p>Access your files everywhere whitout worring about privacy.</p> 
  <div className="justify-center card-actions">
    <button className="btn btn-outline btn-accent">LATER</button>
  </div>
</div>

</div> */}

          </div>
          </center>

</div>

        


        </div>
        <div className="w-screen h-96 bg-[#11131d]">

</div>
 <Darkfoot />

        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default GetStarted

