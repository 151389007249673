import react from "react";
import FootBar from "../footbar";
import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
const Notfound = ()=>{
  const navigate = useNavigate();
    return (
        <>
        <div className="w-full text-white">
     




<center>
<div className="hero w-auto flex mt-8 mx-4 max-w-xl justify-center">
<img alt='404' className='hero' src={'https://www.jea.com/cdn/images/backgrounds/404/404-bg-img.svg'} />
</div>

</center>
<p id='f2' className="mb-5 text-2xl hero mt-5 text-black">
      <Typewriter
  
  onInit={(typewriter)=> {

  typewriter
  .typeString("NOT FOUND 404")
  .start();
  }}
  />
          </p> 





        </div>
<FootBar />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Notfound