import { useNavigate } from 'react-router-dom'
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";
import Darkfoot from "../darkfootbar";
const Contact = ()=>{
  const navigate = useNavigate();
    return (
        <>
                                <Helmet>
          <title>Skyex | Contact</title>
          <meta
      name="description"
    
      content="Skyex Jobs."
    />
        </Helmet>
             <div className="w-screen h-[6rem] -mt-[6.53rem]  scrollbar-hide bg-[#11131d] text-white">

</div>

        <div className="w-full bg-[#11131d] text-white">
     








<div className="text-black text-2xl  hero">
<div id='c1' className="text-white shadow-2xl bg-base-300 m-8 max-w-lg p-6 rounded-lg">
<div className="grid place-items-center">
<div className="mb-3">
    Contact Us
</div>

<a className='mt-3' target="_blank" href='https://discord.gg/KtZhay6wf6'>
      <i className="fab fa-discord text-5xl"></i>
      </a>
      <a className='mt-3' target="_blank" href='https://www.instagram.com/skyex.me/'>
      <i className="fab text-5xl fa-instagram"></i>
      </a>

      <a className='mt-3' target="_blank" href='https://www.facebook.com/skyex.me'>
      <i className="fa-brands fa-facebook text-5xl"></i>
      </a>

      <a href='https://twitter.com/skyex_me' className='mt-3' target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" className="fill-current">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
        </svg>
      </a> 
    </div>
</div>

</div>



        </div>
        <div className='h-[50vh] bg-[#11131d] w-screen'>
                </div>
<Darkfoot />
        </>
    )
}
// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfpasLagF5aIXVv3HCwnWmqLhYFP5BTi8cw&usqp=CAU
export default Contact